<template>
  <div>
    <Pane :showRemarks="false" :bottom="0">
      <a-tabs
        v-model="active"
        :tabBarStyle="{
          margin: '0',
        }"
      >
        <a-tab-pane :key="'1'" tab="公共参数"> </a-tab-pane>

        <a-tab-pane
          v-for="item in paramTypeList"
          :key="item.value"
          :tab="item.name"
        >
        </a-tab-pane>
      </a-tabs>
    </Pane>

    <a-card class="container">
      <a-table
        bordered
        :dataSource="filteredList"
        :pagination="{
          total: filteredList.length,
          pageSize: 10,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column title="序号" width="50px" align="center">
          <template slot-scope="text, row, index">
            {{ index + 1 }}
          </template>
        </a-table-column>

        <a-table-column title="中文名" data-index="name" />
        <a-table-column title="英文名" data-index="code" />
        <a-table-column title="设计值">
          <template slot-scope="text">
            <span>{{ text.designValue }}</span>
          </template>
        </a-table-column>
        <a-table-column title="类型">
          <template slot-scope="text">
            <DataDictFinder
              dictType="bim_value_type"
              :dictValue="String(text.valueType)"
            />
          </template>
        </a-table-column>
        <a-table-column title="单位" data-index="unit" />
        <a-table-column title="参考信息" data-index="remark" />
      </a-table>

      <div class="center">
        <a-space>
          <a-button @click="$close($route.path)">关闭</a-button>
        </a-space>
      </div>
    </a-card>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
import { fetchDetail } from "@/api/parameterization/task";
import { mapGetters } from "vuex";
export default {
  mixins: [watermark],
  data() {
    return {
      active: "",
      id: null,
      detail: {},
      list: [],
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    paramTypeList() {
      return this.findDataDict("bim_param_type");
    },

    filteredList() {
      if (this.active) {
        return this.list.filter((item) => item.paramType === this.active);
      } else {
        return this.list;
      }
    },
  },

  mounted() {
    this.active = this.paramTypeList[0].value;

    const { query } = this.$route;
    const { id } = query || {};
    this.id = id;
    this.getDetail();
  },

  methods: {
    getDetail() {
      fetchDetail({ id: this.id }).then((res) => {
        this.detail = Object.freeze(res);
        if (res.body) {
          const arr = JSON.parse(res.body);
          if (Array.isArray(arr)) {
            this.list = arr;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  padding: 16px;
}

.title {
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 12px;
}

.center {
  margin-top: 80px;
}
</style>

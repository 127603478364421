var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('Pane',{attrs:{"showRemarks":false,"bottom":0}},[_c('a-tabs',{attrs:{"tabBarStyle":{
        margin: '0',
      }},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}},[_c('a-tab-pane',{key:'1',attrs:{"tab":"公共参数"}}),_vm._l((_vm.paramTypeList),function(item){return _c('a-tab-pane',{key:item.value,attrs:{"tab":item.name}})})],2)],1),_c('a-card',{staticClass:"container"},[_c('a-table',{attrs:{"bordered":"","dataSource":_vm.filteredList,"pagination":{
        total: _vm.filteredList.length,
        pageSize: 10,
        showTotal: (total) => `共 ${total} 条记录`,
      }}},[_c('a-table-column',{attrs:{"title":"序号","width":"50px","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(text, row, index){return [_vm._v(" "+_vm._s(index + 1)+" ")]}}])}),_c('a-table-column',{attrs:{"title":"中文名","data-index":"name"}}),_c('a-table-column',{attrs:{"title":"英文名","data-index":"code"}}),_c('a-table-column',{attrs:{"title":"设计值"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('span',[_vm._v(_vm._s(text.designValue))])]}}])}),_c('a-table-column',{attrs:{"title":"类型"},scopedSlots:_vm._u([{key:"default",fn:function(text){return [_c('DataDictFinder',{attrs:{"dictType":"bim_value_type","dictValue":String(text.valueType)}})]}}])}),_c('a-table-column',{attrs:{"title":"单位","data-index":"unit"}}),_c('a-table-column',{attrs:{"title":"参考信息","data-index":"remark"}})],1),_c('div',{staticClass:"center"},[_c('a-space',[_c('a-button',{on:{"click":function($event){return _vm.$close(_vm.$route.path)}}},[_vm._v("关闭")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }